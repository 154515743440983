import React, { useState, useEffect } from "react";

import supabase from "../../util/supabase";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Input from "@mui/base/Input";
import { styled } from "@mui/system";
import { Select, MenuItem } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/material/Alert";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
  },
}));

function EditUserInfo(props) {
  const classes = useStyles();
  const [user, setUser] = useState({
    name: "",
    email: "",
    role: "",
  });
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function returnToUsers(event) {
    event.preventDefault();
    props.setEditUser(null);
  }

  const getUserInfo = async () => {
    const { data, error } = await supabase
      .from("users")
      .select("*")
      .eq("email", props.userEmail)
      .single();

    if (error) console.error("Error fetching user info:", error);
    else return data;
  };

  useEffect(() => {
    const fetchData = async () => {
      const user_info = await getUserInfo();
      setUser({
        id: user_info.id,
        name: user_info.name || "",
        email: user_info.email || "",
        role: user_info.role || "",
      });
      setLoading(false);
    };
    fetchData();
  }, []);

  const updateUser = async () => {
    if (user.role === "MASTER ADMIN") {
      const { error } = await supabase.rpc("set_claim", {
        uid: user.id,
        claim: "userrole",
        value: user.role,
      });
      if (error) {
        console.error("There was an error updating the userrole:", error);
      } else {
        const { updateClaimsAdminError } = await supabase.rpc("set_claim", {
          uid: user.id,
          claim: "claims_admin",
          value: true,
        });
        if (updateClaimsAdminError) {
          console.error(
            "There was an error updating the user:",
            updateClaimsAdminError
          );
        } else {
          const { error: updateUserError } = await supabase
            .from("users")
            .update({ name: user.name, email: user.email, role: user.role })
            .eq("email", props.userEmail);
          if (updateUserError) {
            console.log("Failed to update user");
          } else {
            console.log("Updated user successfully");
          }
        }
      }
    } else {
      const { error } = await supabase.rpc("set_claim", {
        uid: user.id,
        claim: "userrole",
        value: user.role,
      });
      if (error) {
        console.error("There was an error updating the userrole:", error);
      } else {
        const { updateClaimsAdminError } = await supabase.rpc("set_claim", {
          uid: user.id,
          claim: "claims_admin",
          value: false,
        });
        if (updateClaimsAdminError) {
          console.error(
            "There was an error updating the user:",
            updateClaimsAdminError
          );
        } else {
          const { error: updateUserError } = await supabase
            .from("users")
            .update({ name: user.name, email: user.email, role: user.role })
            .eq("email", props.userEmail);
          if (updateUserError) {
            console.log("Failed to update user");
          } else {
            console.log("Updated user successfully");
            setUpdateSuccess(true);
          }
        }
      }
    }
  };

  const removeUser = async () => {
    console.log("Removing user from org");
    console.log("TESTESTSTSESTEST");
    const { error } = await supabase.rpc("reset_user_meta_data", {
      uid: user.id,
    });
    if (error) {
      console.error("Error updating user: ", error);
    } else {
      const { error } = await supabase.rpc("set_claim", {
        uid: user.id,
        claim: "userrole",
        value: "STANDARD",
      });
      if (error) {
        console.error("There was an error updating the userrole:", error);
      } else {
        const { updateClaimsAdminError } = await supabase.rpc("set_claim", {
          uid: user.id,
          claim: "claims_admin",
          value: false,
        });
        if (updateClaimsAdminError) {
          console.error(
            "There was an error updating the user:",
            updateClaimsAdminError
          );
        } else {
          const { error: updateUserError } = await supabase
            .from("users")
            .update({ role: "STANDARD" })
            .eq("email", props.userEmail);
          if (updateUserError) {
            console.error("Failed to update user");
          } else {
            const { error: updateError } = await supabase.rpc(
              "update_user_org_id",
              {
                p_id: user.id,
                p_org_id: null,
              }
            );
            if (updateError) {
              console.error("Failed to remove user from the org");
            }
          }
        }
      }
    }

    handleClose();

    setUser({
      name: "",
      email: "",
      role: "",
    });

    props.setEditUser(null);
  };

  return (
    <>
      <div role="presentation">
        <Box mb={3}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              href="/"
              onClick={returnToUsers}
            >
              Users
            </Link>
            {user.name === "" ? (
              <Typography color="text.primary">{props.userEmail}</Typography>
            ) : (
              <Typography color="text.primary">{user.name}</Typography>
            )}
          </Breadcrumbs>
        </Box>
      </div>

      <Paper>
        <CardContent className={classes.cardContent}>
          {updateSuccess && (
            <Box mb={3}>
              <Alert
                severity="success"
                onClose={() => {
                  setUpdateSuccess(false);
                }}
              >
                User successfully updated!
              </Alert>
            </Box>
          )}
          <Box mb={2}>
            <>
              <InputLabel id="demo-simple-select-label">Name</InputLabel>
              <Input
                slots={{ input: StyledInputElement }}
                placeholder="Name"
                value={user.name}
                onChange={(event) =>
                  setUser({ ...user, name: event.target.value })
                }
              />
            </>
          </Box>
          <Box mb={2}>
            <>
              <InputLabel id="demo-simple-select-label">Email</InputLabel>
              <Input
                slots={{ input: StyledInputElement }}
                placeholder="Email"
                value={user.email}
                onChange={(event) =>
                  setUser({ ...user, email: event.target.value })
                }
              />
            </>
          </Box>
          <Box mb={2}>
            {!loading && (
              <>
                <InputLabel id="demo-simple-select-label">User role</InputLabel>
                <Select
                  label="User role"
                  slots={{ input: StyledInputElement }}
                  value={user.role}
                  onChange={(event) => {
                    setUser({ ...user, role: event.target.value });
                  }}
                >
                  <MenuItem value={"STANDARD"}>Standard</MenuItem>
                  <MenuItem value={"ADMIN"}>Admin</MenuItem>
                  <MenuItem value={"MASTER ADMIN"}>Master admin</MenuItem>
                </Select>
              </>
            )}
          </Box>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Remove user"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to remove this user from your
                organization?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={removeUser} autoFocus>
                Confirm
              </Button>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </Dialog>
          <Box mb={2}>
            <Button onClick={updateUser}>Update</Button>
            <Button color="error" onClick={handleClickOpen}>
              Remove user
            </Button>
          </Box>
        </CardContent>
      </Paper>
    </>
  );
}

export default EditUserInfo;

const blue = {
  100: "#DAECFF",
  200: "#80BFFF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
};

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledInputElement = styled("input")(
  ({ theme }) => `
  width: 320px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[500] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);
