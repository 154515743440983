import * as React from "react";
import { useState, useEffect } from "react";

import supabase from "../../util/supabase";

import { alpha } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddCatalogItemDialog from "./AddCatalogItemDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@material-ui/core";
import Button from "@mui/material/Button";

export default function EnhancedCatalogTableToolbar(props) {
  const numSelected = props.numSelected;
  const [rows, setRows] = useState(props.selected);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteSelectedItems = async () => {
    console.log(props.selected);
    const { error } = await supabase.rpc("remove_items_from_catalog", {
      data: props.selected,
    });
    if (error) {
      console.error(error);
    }
    props.setReloadCatalog(true);
    props.setSelected([]);
    handleClose();
  };

  //   console.log(props);

  useEffect(() => {
    setRows(props.selected);
  }, [props.selected]);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Inventory Catalog
        </Typography>
      )}

      {numSelected > 0 ? (
        <>
          <Tooltip title="Delete Items">
            <IconButton
              onClick={() => {
                handleClickOpen();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Delete catalog items?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete the {props.numSelected} selected
                items from your inventory catalog and all active locations?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="error" onClick={deleteSelectedItems} autoFocus>
                Delete
              </Button>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </Dialog>
          <Box mb={2}></Box>
        </>
      ) : (
        <>
          <AddCatalogItemDialog
            // updateRows={props.updateRows}
            // inventoryItems={props.inventoryItems}
            // locationId={props.locationId}
            // rows={rows}
            // setRows={setRows}
            setReloadCatalog={props.setReloadCatalog}
          />
          {/* <Tooltip title="Filter list">
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip> */}
        </>
      )}
    </Toolbar>
  );
}
