import * as React from "react";
import { useState, useEffect } from "react";

import supabase from "../../util/supabase";

import Tooltip from "@mui/material/Tooltip";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function TransferInventoryDialog(props) {
  const [open, setOpen] = React.useState(false);
  //   const [rows, setRows] = useState(props.selected);
  const [adjustments, setAdjustments] = useState({});
  const [newRows, setNewRows] = useState();
  const [transferLocation, setTransferLocation] = useState("");

  //   let rows = props.rows

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAdjustmentChange = (name, adjustment) => {
    setAdjustments({
      ...adjustments,
      [name]: adjustment,
    });
  };

  useEffect(() => {
    if (newRows && newRows.length > 0) {
      transferInventory();
    }
  }, [newRows]);

  //   useEffect(() => {
  //     setRows(props.selected);
  //   }, [props.selected]);

  const handleSave = () => {
    const updatedRows = props.rows
      .map((row) => {
        const newQuantity = row.quantity - (adjustments[row.name] || 0);
        return newQuantity !== row.quantity
          ? {
              ...row,
              quantity: newQuantity,
              transferAmount: adjustments[row.name],
            }
          : null;
      })
      .filter(Boolean); // This will filter out any null values

    setNewRows(updatedRows);
    setAdjustments({});
    handleClose();
  };

  const transferInventory = async () => {
    const locationId = props.locationId;

    console.log(newRows)

    // Compare 'rows' with 'newRows' and update any matching items
    let updatedRows = props.inventoryItems.map((row) => {
      let match = newRows.find((newRow) => newRow.id === row.id);
      return match ? match : row;
    });

    let updateSelectedRows = props.rows.map((row) => {
      let match = newRows.find((newRow) => newRow.id === row.id);
      return match ? match : row;
    });

    const { error } = await supabase.rpc("transfer_item", {
      location: locationId,
      transfer_location: transferLocation,
      data: newRows,
    });

    if (error) {
      console.error(
        "There was an error updating the location inventory:",
        error
      );
    } else {
      console.log("Location inventory updated");
      props.setRows(updateSelectedRows); // update the rows state with the updated rows
      props.updateRows(updatedRows);
    }
  };

  //   console.log(props.locationGroups);
  //   console.log(props.locations);

  let groupedLocations = props.locationGroups.map((group) => {
    return {
      ...group,
      locations: props.locations
        .filter((location) => location.location_group === group.group_name)
        .filter((location) => location.location_id !== props.locationId), // Filter out the location with matching location_id
    };
  });

  const nonEmptyGroups = groupedLocations.filter(
    (group) => group.locations.length > 0
  );

  const menuItems = nonEmptyGroups.flatMap((group, groupIndex) => [
    <ListSubheader key={groupIndex}>{group.group_name}</ListSubheader>,
    ...group.locations.map((location) => (
      <MenuItem key={location.location_id} value={location.location_id}>
        {location.location_name}
      </MenuItem>
    )),
  ]);

  const handleLocationSelect = (event) => {
    setTransferLocation(event.target.value);
    console.log(event.target.value);
  };

  return (
    <div>
      <Tooltip title="Transfer Inventory">
        <IconButton onClick={handleClickOpen}>
          <SwapHorizIcon />
        </IconButton>
      </Tooltip>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
        fullWidth={true}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Transfer Inventory
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <FormControl sx={{ m: 1, minWidth: 240 }}>
              <InputLabel required={true} htmlFor="grouped-select">
                Select Location
              </InputLabel>
              <Select
                defaultValue=""
                id="grouped-select"
                label="Select Location *" // label should match the text in InputLabel
                value={transferLocation}
                onChange={handleLocationSelect}
              >
                {menuItems}
              </Select>
            </FormControl>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Item Name</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Transfer Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        maxWidth: "200px",
                        // overflow: "hidden",
                        // textOverflow: "ellipsis",
                        // whiteSpace: "nowrap",
                      }}
                    >
                      {row.description}
                    </TableCell>
                    <TableCell align="right">{row.quantity}</TableCell>
                    <TableCell align="right">
                      <TextField
                        label="Transfer"
                        type="number"
                        inputProps={{
                          min: 0,
                          max: row.quantity,
                          style: { width: "6em" }, // Set the desired width (e.g., 200px)
                        }}
                        value={adjustments[row.name] || ""}
                        onChange={(e) =>
                          handleAdjustmentChange(
                            row.name,
                            parseInt(e.target.value, 10)
                          )
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleSave}
            disabled={transferLocation === ""}
          >
            Transfer
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
