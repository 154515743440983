import { useState, useEffect } from "react";

import Section from "../Section";
import SectionHeader from "../SectionHeader";
import DashboardItems from "../DashboardItems";
import { Link, useRouter } from "../../util/router";
import { useAuth } from "../../util/auth";
import supabase from "../../util/supabase";
import ReordersTable from "./ReordersTable";

import { v4 as uuidv4 } from "uuid";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
// import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import LinkMui from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
  },
}));

function DashboardSection(props) {
  const classes = useStyles();

  const auth = useAuth();
  const router = useRouter();
  const [userOrgId, setUserOrgId] = useState(null);
  const [orgName, setOrgName] = useState(null);
  const [inviteLink, setInviteLink] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbarMessage("Link copied to clipboard");
    setSnackbarOpen(true);
  };

  const getUserOrgId = async (id) => {
    const { data, error } = await supabase
      .from("users")
      .select("org_id")
      .eq("id", id)
      .single();
    if (error) console.error("Error fetching user org id:", error);
    else return data.org_id;
  };

  const inviteUser = async () => {
    const inviteId = uuidv4();
    // const orgId = orgs.data[0].org_id;
    const { error } = await supabase
      .from("invites")
      .insert([{ invite_id: inviteId, org_id: userOrgId }]);
    if (error) console.error("Error creating invite:", error);
    else {
      const newInviteLink = `https://www.countableinventory.io/auth/signin?invite_id=${inviteId}`;
      console.log(newInviteLink);
      setInviteLink(newInviteLink);
      copyToClipboard(newInviteLink);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const orgId = await getUserOrgId(auth.user.id);
      console.log(orgId);
      setUserOrgId(orgId);

      if (orgId) {
        const org_name = await supabase
          .from("organizations")
          .select("name")
          .eq("org_id", orgId)
          .single();

        // console.log(org_name.data.name);
        setOrgName(org_name.data.name);

        const { data, error } = await supabase.auth.updateUser({
          data: { org_id: orgId, org_name: org_name.data.name },
        });

        if (error) {
          console.error("Error updating user: ", error);
        } else {
          console.log("User updated successfully: ", data);
        }
      }
    };
    if (!auth.user.user_metadata.org_id && !auth.user.user_metadata.org_name) {
      fetchData();
    } else {
      setUserOrgId(auth.user.user_metadata.org_id);
      setOrgName(auth.user.user_metadata.org_name);
    }
  }, [auth.user.id]);

  if (userOrgId) {
    return (
      <Section
        bgColor={props.bgColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <Container>
          <SectionHeader
            title={orgName}
            subtitle={props.subtitle}
            size={4}
            textAlign="center"
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column", // Change to column to stack the elements vertically
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={inviteUser}
              style={{ width: 150, margin: "10px" }}
            >
              Invite user
            </Button>

            {inviteLink && (
              <Alert
                severity="success"
                onClose={() => {
                  setInviteLink(null);
                }}
                style={{ cursor: "pointer", margin: "10px" }}
              >
                <div onClick={() => copyToClipboard(inviteLink)}>
                  {inviteLink}
                </div>
              </Alert>
            )}
          </div>

          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={() => setSnackbarOpen(false)}
            message={snackbarMessage}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setSnackbarOpen(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
          <ReordersTable />
        </Container>
      </Section>
    );
  } else {
    return (
      <Section
        bgColor={props.bgColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <Container>
          <SectionHeader
            title={"You are not currently associated with an organization"}
            subtitle={props.subtitle}
            size={4}
            textAlign="center"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column", // Change to column to stack the elements vertically
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={inviteUser}
              style={{ width: 150, margin: "10px" }}
            >
              Create organization?
            </Button>
          </div>
        </Container>
      </Section>
      // <div>No org</div>;)
    );
  }
}

export default DashboardSection;
