import React, { useEffect, useState } from "react";

import Meta from "./../components/Meta";
import DashboardSection from "../components/dashboard/DashboardSection";
import { requireAuth } from "./../util/auth";
import supabase from "../util/supabase";
import { useAuth } from "./../util/auth";

import { v4 as uuidv4 } from "uuid";
import Button from "@mui/material/Button";

const DashboardPage = (props) => {
  // const auth = useAuth();
  // const [userOrgId, setUserOrgId] = useState(null);
  // const [orgName, setOrgName] = useState(null);

  // const getUserOrgId = async (id) => {
  //   const { data, error } = await supabase
  //     .from("users")
  //     .select("org_id")
  //     .eq("id", id)
  //     .single();
  //   if (error) console.error("Error fetching user org id:", error);
  //   else return data.org_id;
  // };

  // const inviteUser = async () => {
  //   const inviteId = uuidv4();
  //   // const orgId = orgs.data[0].org_id;
  //   const { error } = await supabase
  //     .from("invites")
  //     .insert([{ invite_id: inviteId, org_id: userOrgId }]);
  //   if (error) console.error("Error creating invite:", error);
  //   else {
  //     console.log("Invite created successfully");
  //     const inviteLink = `https://www.countableinventory.io/auth/signin?invite_id=${inviteId}`;
  //     console.log("Invite link:", inviteLink);
  //   }
  // };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const orgId = await getUserOrgId(auth.user.id);
  //     console.log(orgId);
  //     setUserOrgId(orgId);

  //     if (orgId) {
  //       const org_name = await supabase
  //         .from("organizations")
  //         .select("name")
  //         .eq("org_id", orgId)
  //         .single();

  //       // console.log(org_name.data.name);
  //       setOrgName(org_name.data.name);

  //       const { data, error } = await supabase.auth.updateUser({
  //         data: { org_id: orgId, org_name: org_name.data.name },
  //       });

  //       if (error) {
  //         console.error("Error updating user: ", error);
  //       } else {
  //         console.log("User updated successfully: ", data);
  //       }
  //     }
  //   };
  //   if (!auth.user.user_metadata.org_id && !auth.user.user_metadata.org_name) {
  //     fetchData();
  //   } else {
  //     setUserOrgId(auth.user.user_metadata.org_id);
  //     setOrgName(auth.user.user_metadata.org_name);
  //   }
  // }, [auth.user.id]);

  // if (userOrgId) {
  return (
    <>
      <Meta title="Dashboard" />
      <DashboardSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        // title={orgName}
        subtitle=""
      />
      {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // height: "100vh",
          }}
        >
          <Button
            variant="contained"
            onClick={inviteUser}
            style={{ width: 150 }}
          >
            Invite user
          </Button>
        </div> */}
    </>
  );
  // } else {
  //   return <div>No org</div>;
  // }
};

export default requireAuth(DashboardPage);
