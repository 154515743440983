import { useState, useEffect } from "react";

import supabase from "../../util/supabase";

import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";

export default function AddLocation(props) {
  const [locationGroups, setLocationGroups] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [locationName, setLocationName] = useState("");

  const org_id = JSON.parse(localStorage.getItem("org_id"));

  const getLocationGroups = async () => {
    const { data, error } = await supabase.from("location_groups").select("*");
    if (error) console.error("Error fetching user org id:", error);
    else return data;
  };

  useEffect(() => {
    const fetchData = async () => {
      const location_groups = await getLocationGroups();
      setLocationGroups(location_groups);
    };
    fetchData();
  }, []);

  const handleLocationSelect = (event) => {
    setSelectedGroup(event.target.value);
  };

  const handleLocationNameChange = (event) => {
    setLocationName(event.target.value);
  };

  const onSubmit = async () => {
    console.log(locationName);
    let { data, error } = await supabase.from("locations").insert([
      {
        org_id: org_id,
        location_name: locationName,
        location_group: selectedGroup,
      },
    ]);

    if (error) {
      console.error("Error inserting data: ", error);
    } else {
      setSelectedGroup(null);
      setLocationName("");
      props.setNewLocation(false);
    }
  };

  let menuItems;

  if (locationGroups) {
    menuItems = locationGroups.map((group) => (
      <MenuItem key={group.group_name} value={group.group_name}>
        {group.group_name}
      </MenuItem>
    ));
  }

  return (
    <div>
      <TextField
        sx={{ m: 1, minWidth: 240 }}
        label="Location name"
        value={locationName}
        onChange={handleLocationNameChange}
        variant="outlined"
        fullWidth
      />
      <FormControl sx={{ m: 1, minWidth: 240 }}>
        <InputLabel required={true} htmlFor="grouped-select">
          Select Location
        </InputLabel>
        <Select
          defaultValue=""
          id="grouped-select"
          label="Select Location *"
          value={selectedGroup}
          onChange={handleLocationSelect}
        >
          {menuItems}
        </Select>
      </FormControl>
      <div>
        <div>
          <Button
            sx={{ m: 1 }}
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={!locationName || !selectedGroup}
          >
            Submit
          </Button>
          <Button
            sx={{ m: 1 }}
            variant="contained"
            color="error"
            onClick={() => {
              props.setNewLocation(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}
