import React, { Fragment, useState, useEffect, useRef } from "react";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import QRCodeSVG from "qrcode.react";
import QrCodeIcon from "@mui/icons-material/QrCode";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function QrCodeDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [qrCodeSize, setQrCodeSize] = useState(250);
  const [textSize, setTextSize] = useState(qrCodeSize / 10);
  //   const [qrCodeHeight, setQrCodeHeight] = useState(0);
  const locationId = props.locationId;
  const qrCodeRef = useRef(null);
  const selectedItems = props.selected;

  //   useEffect(() => {
  //     if (qrCodeRef.current) {
  //       setQrCodeHeight(qrCodeRef.current.offsetHeight);
  //     }
  //   }, [qrCodeSize, selectedItems]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let changeValue = 3;
  let changeValue2 = 20;

  const handleSliderChange = (event, newValue) => {
    setQrCodeSize((newValue + changeValue) * changeValue2);
    setTextSize(((newValue + changeValue) * changeValue2) / 10);
  };

  const handlePrint = () => {
    let printSection = document.querySelector(".print-section");

    // Clear the print section first
    printSection.innerHTML = "";

    selectedItems.forEach((selectedItem, index) => {
      const updatedSelectedItem = {
        ...selectedItem,
        locationId: locationId,
      };

      let printItem = document.createElement("div");
      printItem.style.pageBreakInside = "avoid";
      printItem.innerHTML = `
        <div style={{ fontSize: "20px", marginBottom: "10px" }}>
          ${updatedSelectedItem.name}
        </div>
        <QRCodeSVG size={250} value=${JSON.stringify(updatedSelectedItem)} />
      `;

      printSection.appendChild(printItem);
    });

    // Display the print section, print it, then hide it again
    printSection.style.display = "block";
    window.print();
    printSection.style.display = "none";
  };

  return (
    <Fragment>
      <style>
        {`
          @media print {
            body * {
              visibility: hidden;
            }
            .print-container, .print-container * {
              visibility: visible;
            }
            .print-container {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
            }
            .print-container > div {
              display: flex;
              flex-wrap: wrap;
            }
            .print-container > div > div {
              page-break-inside: avoid;
              margin: 20px;
            }
          }
        `}
      </style>

      <div>
        <Tooltip title="Generate QR codes">
          <IconButton onClick={handleClickOpen}>
            <QrCodeIcon />
          </IconButton>
        </Tooltip>
        <div>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth="md"
            fullWidth={true}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              Generate QR Code
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <div
                className="print-container"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                {selectedItems.map((selectedItem, index) => {
                  const updatedSelectedItem = {
                    id: selectedItem.id,
                    name: selectedItem.name,
                    description: selectedItem.description,
                    locationId: locationId,
                  };

                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        pageBreakInside: "avoid",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        margin: 8,
                      }}
                    >
                      <div
                        style={{
                          fontSize: `${textSize}px`,
                          marginBottom: `${textSize / 5}px`,
                        }}
                      >
                        {updatedSelectedItem.name}
                      </div>
                      <div ref={index === 0 ? qrCodeRef : null}>
                        <QRCodeSVG
                          size={qrCodeSize}
                          value={JSON.stringify(updatedSelectedItem)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </DialogContent>
            <DialogActions style={{ justifyContent: "space-between" }}>
              <Box width={300} style={{ margin: "auto" }}>
                <Slider
                  value={qrCodeSize / changeValue2 - changeValue}
                  onChange={handleSliderChange}
                  aria-label="Default"
                  valueLabelDisplay="auto"
                  min={1}
                  max={30}
                />
              </Box>
              <Button autoFocus onClick={handlePrint}>
                Print
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </div>

        <div className="print-section" style={{ display: "none" }}>
          <div className="qr-codes-print">
            {selectedItems.map((selectedItem, index) => {
              const updatedSelectedItem = {
                ...selectedItem,
                locationId: locationId,
              };
              return (
                <div key={index}>
                  <div
                    style={{
                      fontSize: `${textSize}px`,
                      marginBottom: `${textSize / 5}px`,
                    }}
                  >
                    {updatedSelectedItem.name}
                  </div>
                  <QRCodeSVG
                    size={250}
                    value={JSON.stringify(updatedSelectedItem)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
