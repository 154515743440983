import * as React from "react";
import { useState, useEffect } from "react";

import supabase from "../../util/supabase";

import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function EditLocationDialog(props) {
  const [open, setOpen] = React.useState(false);
  // const [rows, setRows] = useState(props.selected);
  const [adjustments, setAdjustments] = useState({});
  const [newRows, setNewRows] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAdjustmentChange = (name, adjustment) => {
    setAdjustments({
      ...adjustments,
      [name]: adjustment,
    });
  };

  useEffect(() => {
    if (newRows && newRows.length > 0) {
      updateInventory();
    }
  }, [newRows]);

  // useEffect(() => {
  //   setRows(props.selected);
  // }, [props.selected]);

  const handleSave = () => {
    const updatedRows = props.rows
      .map((row) => {
        const newQuantity =
          adjustments[row.name] !== undefined
            ? adjustments[row.name]
            : row.min_quantity;
        return newQuantity !== row.min_quantity
          ? { ...row, min_quantity: newQuantity }
          : null;
      })
      .filter(Boolean); // This will filter out any null values

    setNewRows(updatedRows);
    setAdjustments({});
    handleClose();
  };

  const updateInventory = async () => {
    const locationId = props.locationId;

    // updates client side view of inventory with requested changes
    let updatedRows = props.inventoryItems.map((row) => {
      let match = newRows.find((newRow) => newRow.id === row.id);
      return match ? match : row;
    });

    // Compare 'rows' with 'newRows' and update any matching items
    let updateSelectedRows = props.rows.map((row) => {
      let match = newRows.find((newRow) => newRow.id === row.id);
      return match ? match : row;
    });

    const { error } = await supabase.rpc("update_min_quantity", {
      location: locationId,
      data: updatedRows, // use the updatedRows
    });

    if (error) {
      console.error(
        "There was an error updating the location inventory:",
        error
      );
    } else {
      console.log("Location inventory updated");
      props.setRows(updateSelectedRows); // update the rows state with the updated rows
      props.updateRows(updatedRows);
    }
  };

  return (
    <div>
      <Tooltip title="Edit Inventory">
        <IconButton onClick={handleClickOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
        fullWidth={true}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Edit Inventory
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Item Name</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Min Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        maxWidth: "200px",
                        // overflow: "hidden",
                        // textOverflow: "ellipsis",
                        // whiteSpace: "nowrap",
                      }}
                    >
                      {row.description}
                    </TableCell>
                    <TableCell align="right">{row.quantity}</TableCell>
                    <TableCell align="right">
                      <TextField
                        label="Min Quantity"
                        type="number"
                        inputProps={{
                          min: -row.quantity,
                          style: { maxWidth: "6em" }, // Set the desired width (e.g., 200px)
                        }}
                        value={adjustments[row.name] || row.min_quantity}
                        onChange={(e) =>
                          handleAdjustmentChange(
                            row.name,
                            parseInt(e.target.value, 10)
                          )
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSave}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
