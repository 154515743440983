import React from "react";
import Meta from "./../components/Meta";
import { requireAuth } from "./../util/auth";
import UserManagmentSection from "../components/users/UserManagementSection";

function UserManagementPage(props) {
  return (
    <>
      <Meta title="Users" />
      <UserManagmentSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        // title="User Management"
        subtitle=""
        buttonText="Send message"
        buttonColor="primary"
        showNameField={true}
      />
    </>
  );
}

export default requireAuth(UserManagementPage);
