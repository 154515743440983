import * as React from "react";
import { useState } from "react";

import supabase from "../../util/supabase";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";

export default function DeleteLocationDialog(props) {
  const [open, setOpen] = useState(false);

  const location = props.selectedInventory;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteLocation = async () => {
    const { error: deleteLocationError } = await supabase
      .from("locations")
      .delete()
      .eq("location_id", location.location_id);
    // Invalidate and refetch queries that could have old data
    if (deleteLocationError) {
      console.error(deleteLocationError);
    }

    setOpen(false);
    props.setSelectedInventory(null);
  };

  return (
    <div>
      {" "}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Location"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove {location.location_name} and all the
            locations inventory?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteLocation} autoFocus>
            Confirm
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="error" onClick={handleClickOpen}>
        Delete location
      </Button>
    </div>
  );
}
