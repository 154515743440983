import React, { useState, useEffect } from "react";

import supabase from "../../util/supabase";
import EnhancedReordersTableHead from "./EnhancedReordersTableHead";
import EnhancedReordersTableToolbar from "./EnhancedReordersTableToolbar";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

function createData(
  id,
  location_name,
  item_name,
  quantity,
  min_quantity,
  status
) {
  return {
    id,
    location_name,
    item_name,
    quantity,
    min_quantity,
    status,
  };
}

function descendingComparator(a, b, orderBy) {
  let aValue =
    typeof a[orderBy] === "string" ? a[orderBy].toLowerCase() : a[orderBy];
  let bValue =
    typeof b[orderBy] === "string" ? b[orderBy].toLowerCase() : b[orderBy];

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

EnhancedReordersTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

EnhancedReordersTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function ReordersTable(props) {
  const [reorders, setReorders] = useState(null);

  const getReorders = async () => {
    const { data, error } = await supabase
      .from("reorders")
      .select(
        "*, locations:location_id (location_name), inventory_catalog:item_id (item_name, item_description)"
      );
    if (error) console.error("Error fetching locations:", error);
    else return data;
  };

  useEffect(() => {
    const fetchData = async () => {
      const reorderData = await getReorders();
      console.log(reorderData);
      setReorders(reorderData);
    };
    fetchData();
  }, []);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("location_name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loading, setLoading] = React.useState(true);
  const [rows, setRows] = useState([]);

  // const role = tokendata.user.app_metadata.userrole;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const reorder_data = await getReorders();
      if (reorder_data) {
        const newRows = reorder_data.map((reorderData) =>
          createData(
            reorderData.item_id,
            reorderData.locations.location_name,
            reorderData.inventory_catalog.item_name,
            reorderData.quantity,
            reorderData.min_quantity,
            reorderData.status
          )
        );
        setRows(newRows);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows;
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (row) =>
    selected.some((item) => item.item_name === row.item_name);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedReordersTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedReordersTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell component="th" scope="row" colSpan={5}>
                      Loading...
                    </TableCell>
                  </TableRow>
                ) : (
                  stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          tabIndex={-1}
                          key={row.id + "_" + row.location_name}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            // padding="none"
                          >
                            {row.location_name}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              maxWidth: "200px",
                            }}
                          >
                            {row.item_name}
                          </TableCell>
                          <TableCell align="left">{row.quantity}</TableCell>
                          <TableCell align="left">{row.min_quantity}</TableCell>
                          <TableCell align="left">{row.status}</TableCell>
                        </TableRow>
                      );
                    })
                )}

                {loading ||
                  (emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
    </>
  );
}

export default ReordersTable;
