import React, { useState, useEffect } from "react";

import supabase from "../../util/supabase";
import MoveLocationGroupDialog from "./MoveLocationGroupDialog";
import NewGroupDialog from "./NewGroupDialog";

import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";

function GroupManagement(props) {
  const [locations, setLocations] = useState(null);
  const [locationGroups, setLocationGroups] = useState(null);
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [newLocation, setNewLocation] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [updateData, setUpdateData] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDialogGroup, setOpenDialogGroup] = useState(null);

  const handleClickOpen = (group) => {
    setOpenDialogGroup(group);
  };

  const handleClose = () => {
    setOpenDialogGroup(null);
  };

  const getLocations = async () => {
    const { data, error } = await supabase.from("locations").select("*");
    if (error) console.error("Error fetching locations:", error);
    else return data;
  };

  const getLocationGroups = async () => {
    const { data, error } = await supabase.from("location_groups").select("*");
    if (error) console.error("Error fetching location groups:", error);
    else return data;
  };

  const deleteGroup = async (group) => {
    const { error: updateGroupError } = await supabase
      .from("location_groups")
      .delete()
      .eq("group_name", group.group_name)
      .eq("org_id", group.org_id);
    if (updateGroupError)
      console.error("Error deleting group:", updateGroupError);

    setUpdateData(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      const locations = await getLocations();
      setLocations(locations);
      const location_groups = await getLocationGroups();
      setLocationGroups(location_groups);
      if (updateData === true) {
        setUpdateData(false);
      }
    };
    fetchData();
  }, [updateData]);

  return (
    <>
      <NewGroupDialog setUpdateData={setUpdateData} />
      <div>
        {locationGroups &&
          [...locationGroups]
            .sort((a, b) => a.group_name.localeCompare(b.group_name))
            .map((group, index) => {
              let relatedLocations = locations.filter(
                (location) => location.location_group === group.group_name
              );
              relatedLocations.sort((a, b) =>
                a.location_name.localeCompare(b.location_name)
              );
              return (
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index + 1}a-content`}
                    id={`panel${index + 1}a-header`}
                  >
                    <Typography>{group.group_name}</Typography>
                  </AccordionSummary>
                  <List>
                    {relatedLocations.map((location) => (
                      <ListItem
                        key={location.location_id}
                        onMouseEnter={() =>
                          setHoveredItem(location.location_id)
                        }
                        // onMouseLeave={() => setHoveredItem(null)}
                        secondaryAction={
                          hoveredItem === location.location_id && (
                            <MoveLocationGroupDialog
                              location={location}
                              locationGroups={locationGroups}
                              setUpdateData={setUpdateData}
                            />
                          )
                        }
                      >
                        <ListItemText primary={location.location_name} />
                      </ListItem>
                    ))}
                  </List>
                  <Dialog
                    open={openDialogGroup === group}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Delete Group"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Are you sure you want delete the group "
                        {group.group_name}"?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => deleteGroup(group)} autoFocus>
                        Confirm
                      </Button>
                      <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                  </Dialog>
                  <Button
                    onClick={() => handleClickOpen(group)}
                    variant="outlined"
                    color="error"
                    style={{ margin: "10px" }}
                  >
                    Delete group
                  </Button>
                </Accordion>
              );
            })}
      </div>
    </>
  );
}

export default GroupManagement;
