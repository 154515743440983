import * as React from "react";
import { useState, useEffect } from "react";

import supabase from "../../util/supabase";

import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddInventoryDialog(props) {
  const [open, setOpen] = React.useState(false);
  // const [rows, setRows] = useState(props.selected);
  const [adjustments, setAdjustments] = useState({});
  const [catalogItems, setCatalogItems] = useState([]);
  const [savedCatalogItems, setSavedCatalogItems] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getInventoryCatalog = async () => {
    const { data, error } = await supabase
      .from("inventory_catalog")
      .select("*");
    if (error) console.error("Error fetching location inventory:", error);
    else return data;
  };

  useEffect(() => {
    if (savedCatalogItems && savedCatalogItems.length > 0) {
      updateInventory();
    }
  }, [savedCatalogItems]);

  useEffect(() => {
    const fetchCatalogItems = async () => {
      const catalog_items = await getInventoryCatalog();
      const inventoryItemIds = new Set(
        props.inventoryItems.map((item) => item.id)
      );
      const filtered_catalog_items = catalog_items.filter(
        (item) => !inventoryItemIds.has(item.item_id)
      );
      setCatalogItems(filtered_catalog_items);
    };
    fetchCatalogItems();
  }, [props.inventoryItems]);

  const handleAdjustmentChange = (id, adjustment) => {
    if (adjustment === 0) {
      setAdjustments((prevAdjustments) => {
        const newAdjustments = { ...prevAdjustments };
        delete newAdjustments[id];
        return newAdjustments;
      });
    } else {
      setAdjustments((prevAdjustments) => ({
        ...prevAdjustments,
        [id]: adjustment,
      }));
    }
  };

  const handleSave = () => {
    const savedItems = catalogItems
      .filter((item) => item.item_id in adjustments)
      .map((item) => ({
        ...item,
        quantity: adjustments[item.item_id],
      }));
    setSavedCatalogItems(savedItems);
    setAdjustments({});
    handleClose();
  };

  const updateInventory = async () => {
    // Merge the original inventory with the saved catalog items
    const mergedInventory = [...props.inventoryItems];

    // Update the structure of savedCatalogItems to match props.inventoryItems
    savedCatalogItems.forEach((savedItem) => {
      const updatedItem = {
        id: savedItem.item_id,
        name: savedItem.item_name,
        description: savedItem.item_description,
        quantity: savedItem.quantity,
        min_quantity: 0,
      };
      mergedInventory.push(updatedItem);
    });

    const locationId = props.locationId;

    const { error } = await supabase.rpc("add_location_inventory", {
      location: locationId,
      data: savedCatalogItems, // use the updatedRows
    });
    if (error) {
      console.error(
        "There was an error updating the location inventory:",
        error
      );
    } else {
      console.log("Location inventory updated");
    }

    props.updateRows(mergedInventory);
  };

  return (
    <div>
      <Tooltip title="Add Inventory">
        <IconButton onClick={handleClickOpen}>
          <AddIcon />
        </IconButton>
      </Tooltip>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
        fullWidth={true}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Add Inventory Item
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Item ID</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="center">Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {catalogItems.map((item) => (
                  <TableRow
                    key={item.item_id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {item.item_id}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        maxWidth: "200px",
                      }}
                    >
                      {item.item_name}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        maxWidth: "200px",
                      }}
                    >
                      {item.item_description}
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        label="Add"
                        type="number"
                        inputProps={{
                          min: 0,
                          style: { maxWidth: "6em" }, // Set the desired width (e.g., 200px)
                        }}
                        value={adjustments[item.item_id] || ""}
                        onChange={(e) =>
                          handleAdjustmentChange(
                            item.item_id,
                            parseInt(e.target.value, 10)
                          )
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSave}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
