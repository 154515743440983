import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import AuthForm from "./AuthForm";
import AuthSocial from "./AuthSocial";
import { useRouter } from "./../util/router";
import supabase from "../util/supabase";

function Auth(props) {
  const router = useRouter();
  const [formAlert, setFormAlert] = useState(null);

  // Handeles the invite for users that already have accounts
  async function handleInvite(user) {
    const urlParams = new URLSearchParams(window.location.search);
    const inviteId = urlParams.get("invite_id");

    if (inviteId) {
      const { data: invite, error } = await supabase
        .from("invites")
        .select("org_id")
        .eq("invite_id", inviteId)
        .single();

      if (error || !invite) {
        console.log("Invalid invite");
        return;
      }
      const user_id = user.id;
      const org_id = invite.org_id;

      console.log(user_id);
      console.log(org_id);

      const { error: updateError } = await supabase.rpc("update_user_org_id", {
        p_id: user.id,
        p_org_id: invite.org_id,
      });
      if (updateError) {
        console.log("Failed to update user org_id");
      } else {
        console.log("Updated user org_id successfully");
        const { error: updateAcceptedError } = await supabase
          .from("invites")
          .update({ accepted: true })
          .eq("invite_id", inviteId);
        if (updateAcceptedError) {
          console.log("Failed to update invite accepted status");
        } else {
          console.log("Updated invite accepted status successfully");
        }
      }

      const org_name = await supabase
        .from("organizations")
        .select("name")
        .eq("org_id", org_id)
        .single();
      console.log(user_id);
      console.log(org_id);
      console.log(org_name.data.name);

      const { error: updateDataError } = await supabase.rpc(
        "set_user_meta_data",
        {
          uid: user_id,
          org_id: org_id,
          org_name: org_name.data.name,
        }
      );
      if (updateDataError) {
        console.error("Failed to update user_metadata");
      }
    }
  }

  const handleAuth = async (user) => {
    const urlParams = new URLSearchParams(window.location.search);
    let inviteId = urlParams.get("invite_id");
    if (inviteId !== null) {
      await handleInvite(user);
    }
    router.push(props.afterAuthPath);
  };

  const handleFormAlert = (data) => {
    setFormAlert(data);
  };

  return (
    <>
      {formAlert && (
        <Box mb={3}>
          <Alert severity={formAlert.type}>{formAlert.message}</Alert>
        </Box>
      )}

      <AuthForm
        type={props.type}
        buttonAction={props.buttonAction}
        onAuth={handleAuth}
        onFormAlert={handleFormAlert}
      />

      {["signup", "signin"].includes(props.type) && (
        <>
          {props.providers && props.providers.length && (
            <>
              <Box textAlign="center" fontSize={12} my={2}>
                OR
              </Box>
              <AuthSocial
                buttonAction={props.buttonAction}
                providers={props.providers}
                showLastUsed={true}
                onAuth={handleAuth}
                onError={(message) => {
                  handleFormAlert({
                    type: "error",
                    message: message,
                  });
                }}
              />
            </>
          )}
        </>
      )}
    </>
  );
}

export default Auth;
