import React from "react";

import Section from "../Section";
import SectionHeader from "../SectionHeader";
import InventoryCatalog from "./InventoryCatalog";

import Container from "@material-ui/core/Container";

function InventoryCatalogSection(props) {
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <InventoryCatalog />
      </Container>
    </Section>
  );
}

export default InventoryCatalogSection;
