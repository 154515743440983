import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
// import StatsSection from "./../components/StatsSection";
// import TeamBiosSection from "./../components/TeamBiosSection";
import CtaSection from "./../components/CtaSection";

function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <HeroSection2
        bgColor="default"
        size="large"
        bgImage=""
        bgImageOpacity={1}
        title="Simple, Efficient, Scalable."
        subtitle={
          <>
            CoutableInventory.io was developed because we found that among many
            inventory management solutions out there, very few, if any, offered
            a simple, flexible, and affordable solution for tracking inventory
            that isn’t sold to customers.
            <br />
            <br />
            Many small businesses need to track, transfer, or be able to know
            when to reorder inventory without stopping what they are doing,
            walking into the warehouse to count what they have, or interrupt
            someone else’s tasks to do it for them.
            <br />
            <br />
            Often, inventory levels are kept on a spreadsheet, a clip board, or
            in someone’s head as a “About this many” number. We know from
            personal experience that these systems lead to either an overstock
            of hidden inventory that ties up operating capital, or worse,
            finding out you don’t have what you need when you need it.
            <br />
            <br />
            Our mission is to give you the tools you need to manage your
            inventory at any location with superior accuracy, in the least
            amount of time, from any Android or IOS device, anywhere you have
            internet access.
            <br />
            <br />
            Ask us how.
          </>
        }
      />
      {/* <StatsSection
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
      />
      <TeamBiosSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Meet the Team"
        subtitle=""
      /> */}
      <CtaSection
        bgColor="primary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="default"
        buttonPath="/contact"
      />
    </>
  );
}

export default AboutPage;
