import * as React from "react";
import { useState } from "react";

import supabase from "../../util/supabase";

import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

const org_id = JSON.parse(localStorage.getItem("org_id"));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddCatalogItemDialog(props) {
  const [open, setOpen] = useState(false);
  const [itemName, setItemName] = useState("");
  const [itemDescription, setItemDescription] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setItemName("");
    setItemDescription("");
    setOpen(false);
  };

  const handleSave = async () => {
    try {
      let { data, error } = await supabase.from("inventory_catalog").insert([
        {
          org_id: org_id,
          item_name: itemName,
          item_description: itemDescription,
        },
      ]);

      if (error) {
        throw error;
      }
    } catch (error) {
      console.error("Error inserting data: ", error);
    }
    props.setReloadCatalog(true);

    handleClose();
  };

  return (
    <div>
      <Tooltip title="Add Catalog Item">
        <IconButton onClick={handleClickOpen}>
          <AddIcon />
        </IconButton>
      </Tooltip>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
        fullWidth={true}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Add Catalog Item
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <FormControl fullWidth>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="80%"
              mx="auto" // This ensures that the remaining 20% space is equally distributed on left and right.
            >
              <TextField
                id="item-name"
                label="Item Name"
                variant="outlined"
                onChange={(e) => setItemName(e.target.value)}
                fullWidth
                style={{ marginBottom: "15px" }}
                inputProps={{
                  maxLength: 50,
                }}
              />
              <TextField
                id="item-description"
                label="Item Description"
                multiline
                rows={4}
                variant="outlined"
                onChange={(e) => setItemDescription(e.target.value)}
                inputProps={{
                  maxLength: 160,
                }}
                fullWidth
              />
            </Box>
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button
            autoFocus
            onClick={handleSave}
            disabled={!itemName || !itemDescription}
          >
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
