import * as React from "react";
import { useState, useEffect } from "react";

import supabase from "../../util/supabase";

import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function NewGroupDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [groupName, setGroupName] = useState("");
  const [transferLocation, setTransferLocation] = useState("");

  const org_id = JSON.parse(localStorage.getItem("org_id"));

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLocationSelect = (event) => {
    setTransferLocation(event.target.value);
  };

  const handleSave = async () => {
    // console.log(groupName);

    const { error } = await supabase
      .from("location_groups")
      .insert([{ org_id: org_id, group_name: groupName }]);
    if (error) console.error("Error creating invite:", error);

    props.setUpdateData(true);
    handleClose();
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        style={{ margin: "10px", marginLeft: 0 }}
        onClick={handleClickOpen}
      >
        New Group
      </Button>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth={true}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Create Group
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <FormControl sx={{ m: 1, width: "90%" }}>
              <TextField
                id="group-name"
                label="Group Name"
                variant="outlined"
                onChange={(e) => setGroupName(e.target.value)}
                fullWidth
                style={{ marginBottom: "15px" }}
                inputProps={{
                  maxLength: 60,
                }}
              />
            </FormControl>
          </div>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleSave} disabled={groupName === ""}>
            Add Group
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
