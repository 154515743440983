import * as React from "react";
import { useState, useEffect } from "react";

import supabase from "../../util/supabase";

import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function MoveLocationGroupDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [transferLocation, setTransferLocation] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLocationSelect = (event) => {
    setTransferLocation(event.target.value);
  };

  const handleSave = async () => {
    if (transferLocation === props.location.location_group) {
      handleClose();
    } else {
      const { error: updateGroupError } = await supabase
        .from("locations")
        .update({ location_group: transferLocation })
        .eq("location_id", props.location.location_id);
      if (updateGroupError) {
        console.error(updateGroupError);
      }
      props.setUpdateData(true);
      handleClose();
    }
  };

  const menuItems = props.locationGroups.map((group, index) => (
    <MenuItem key={index} value={group.group_name}>
      {group.group_name}
    </MenuItem>
  ));

  return (
    <div>
      <Tooltip title="Transfer Inventory">
        <IconButton onClick={handleClickOpen}>
          <ArrowForwardIcon />
        </IconButton>
      </Tooltip>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth={true}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Move Location Group
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <FormControl sx={{ m: 1, minWidth: 240 }}>
              <InputLabel required={true} htmlFor="grouped-select">
                Select Group
              </InputLabel>
              <Select
                defaultValue=""
                id="grouped-select"
                label="Select Location *"
                value={transferLocation}
                onChange={handleLocationSelect}
              >
                {menuItems}
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleSave}
            disabled={transferLocation === ""}
          >
            Move
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
