import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import ClientsSection from "./../components/ClientsSection";
import TestimonialsSection from "./../components/TestimonialsSection";
import NewsletterSection from "./../components/NewsletterSection";
import CtaSection from "./../components/CtaSection";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection
        bgColor="primary"
        size="large"
        bgImage="https://images.pexels.com/photos/1797428/pexels-photo-1797428.jpeg"
        bgImageOpacity={0.2}
        title="Simple, Efficient, Scalable."
        subtitle={
          <>
            CountableInvetory.io offers an affordable and easy to use solution
            for tracking and managing the inventory you use to run your
            business.
            <br />
            <br />
            With multi-location functionality, manage inventory across your
            warehouses, fleet vehicles, job locations, customer venues, and
            more.
            <br />
            <br />
            Knowing what you have, where it is, and when you need to reorder,
            all in real time will be a game changer.
            <br />
            <br />
            Let us show you how.
          </>
        }
        buttonText="Schedule a demo"
        buttonColor="default"
        buttonPath="/contact"
      />

      {/* <FeaturesSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Features"
        subtitle="All the features you need to move faster"
      />
      <ClientsSection
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="You're in good company"
        subtitle=""
      />
      <TestimonialsSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Here's what people are saying"
        subtitle=""
      />
      <NewsletterSection
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in the know"
        subtitle="Receive our latest articles and feature updates"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      />
      <CtaSection
        bgColor="primary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="default"
        buttonPath="/pricing"
      /> */}
    </>
  );
}

export default IndexPage;
