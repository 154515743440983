import React from "react";
import Meta from "./../components/Meta";
import { requireAuth } from "./../util/auth";
import LocationManagementSection from "../components/location management/LocationManagementSection";

function LocationManagementPage(props) {
  return (
    <>
      <Meta title="Location Management" />
      <LocationManagementSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Location Management"
        subtitle=""
        buttonText="Send message"
        buttonColor="primary"
        showNameField={true}
      />
    </>
  );
}

export default requireAuth(LocationManagementPage);
