import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";
import { useDarkMode } from "./../util/theme";
import supabase from "../util/supabase";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 28,
    marginRight: theme.spacing(2),
  },
  drawerList: {
    width: 250,
  },
  spacer: {
    flexGrow: 1,
  },
}));

function Navbar(props) {
  const classes = useStyles();

  const auth = useAuth();

  // console.log(auth.user.user_metadata.org_id);

  // supabase.auth.onAuthStateChange((_event, session) => {
  //   if (session?.user) {
  //     console.log(session?.user?.app_metadata); // show custom claims
  //   }
  // });

  // if (auth.user) {
  //   console.log(auth.user.app_metadata.userrole);
  // }
  // console.log(auth);

  // call select get_my_claim('userlevel'); from supabase
  // useEffect(() => {
  //   async function fetchMyClaim() {
  //     const { data, error } = await supabase.rpc("is_claims_admin");

  //     if (error) {
  //       console.error("Error fetching my claim: ", error);
  //     } else {
  //       console.log("My claim data: ", data);
  //     }
  //   }

  //   if (auth.user) {
  //     fetchMyClaim();
  //   }
  // }, [auth.user]);

  const darkMode = useDarkMode(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuState, setMenuState] = useState(null);

  // Use inverted logo if specified
  // and we are in dark mode
  const logo =
    props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

  const handleOpenMenu = (event, id) => {
    // Store clicked element (to anchor the menu to)
    // and the menu id so we can tell which menu is open.
    setMenuState({ anchor: event.currentTarget, id });
  };

  const handleCloseMenu = () => {
    setMenuState(null);
  };

  // useEffect(() => {
  //   async function updateUser() {
  //     const { data, error } = await supabase.auth.updateUser({
  //       data: { hello: "world" },
  //     });

  //     if (error) {
  //       console.error("Error updating user: ", error);
  //     } else {
  //       console.log("User updated successfully: ", data);
  //     }
  //   }

  //   updateUser();
  // }, []); // Empty dependency array means this effect runs once on mount

  return (
    <Section bgColor={props.color} size="auto">
      <AppBar position="static" color="transparent" elevation={0}>
        <Container disableGutters={true}>
          <Toolbar>
            <Link to="/">
              <img src={logo} alt="Logo" className={classes.logo} />
            </Link>
            {!auth.user && (
              <Hidden smDown={true} implementation="css">
                <Button component={Link} to="/about" color="inherit">
                  About
                </Button>
                <Button component={Link} to="/pricing" color="inherit">
                  Pricing
                </Button>
                <Button component={Link} to="/faq" color="inherit">
                  FAQ
                </Button>
                <Button component={Link} to="/contact" color="inherit">
                  Contact Us
                </Button>
                {/* <Button
                  color="inherit"
                  aria-label="More"
                  aria-controls="more-menu"
                  aria-haspopup="true"
                  onClick={(event) => {
                    handleOpenMenu(event, "more-menu");
                  }}
                >
                  More
                  <ExpandMoreIcon className={classes.buttonIcon} />
                </Button>
                <Menu
                  id="more-menu"
                  open={
                    menuState && menuState.id === "more-menu" ? true : false
                  }
                  anchorEl={menuState && menuState.anchor}
                  getContentAnchorEl={undefined}
                  onClick={handleCloseMenu}
                  onClose={handleCloseMenu}
                  keepMounted={true}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <MenuItem component={Link} to="/faq">
                    FAQ
                  </MenuItem>
                  <MenuItem component={Link} to="/contact">
                    Contact
                  </MenuItem>
                  <MenuItem
                    component="a"
                    href="https://medium.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Blog
                  </MenuItem>
                </Menu> */}
              </Hidden>
            )}

            {auth.user && (
              <Hidden smDown={true} implementation="css">
                <Button component={Link} to="/dashboard" color="inherit">
                  Dashboard
                </Button>

                {auth.user.user_metadata.org_id && (
                  <Button component={Link} to="/inventory" color="inherit">
                    Inventory
                  </Button>
                )}
                {(auth.user.app_metadata.userrole === "ADMIN" ||
                  auth.user.app_metadata.userrole === "MASTER ADMIN") && (
                  <>
                    <Button
                      color="inherit"
                      aria-label="More"
                      aria-controls="more-menu"
                      aria-haspopup="true"
                      onClick={(event) => {
                        handleOpenMenu(event, "more-menu");
                      }}
                    >
                      Admin
                      <ExpandMoreIcon className={classes.buttonIcon} />
                    </Button>
                    <Menu
                      id="more-menu"
                      open={
                        menuState && menuState.id === "more-menu" ? true : false
                      }
                      anchorEl={menuState && menuState.anchor}
                      getContentAnchorEl={undefined}
                      onClick={handleCloseMenu}
                      onClose={handleCloseMenu}
                      keepMounted={true}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <MenuItem component={Link} to="/users">
                        Users
                      </MenuItem>
                      <MenuItem component={Link} to="/catalog">
                        Inventory Catalog
                      </MenuItem>
                      <MenuItem component={Link} to="/locations">
                        Location Management
                      </MenuItem>
                      <MenuItem component={Link} to="/groups">
                        Group Management
                      </MenuItem>
                      {/* <MenuItem component={Link} to="/faq">
                        FAQ
                      </MenuItem>
                      <MenuItem component={Link} to="/contact">
                        Contact
                      </MenuItem>
                      <MenuItem
                        component="a"
                        href="https://medium.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Blog
                      </MenuItem> */}
                    </Menu>
                  </>
                )}

                {/* <Button
                  color="inherit"
                  aria-label="More"
                  aria-controls="more-menu"
                  aria-haspopup="true"
                  onClick={(event) => {
                    handleOpenMenu(event, "more-menu");
                  }}
                >
                  More
                  <ExpandMoreIcon className={classes.buttonIcon} />
                </Button>
                <Menu
                  id="more-menu"
                  open={
                    menuState && menuState.id === "more-menu" ? true : false
                  }
                  anchorEl={menuState && menuState.anchor}
                  getContentAnchorEl={undefined}
                  onClick={handleCloseMenu}
                  onClose={handleCloseMenu}
                  keepMounted={true}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <MenuItem component={Link} to="/faq">
                    FAQ
                  </MenuItem>
                  <MenuItem component={Link} to="/contact">
                    Contact
                  </MenuItem>
                  <MenuItem
                    component="a"
                    href="https://medium.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Blog
                  </MenuItem>
                </Menu> */}
              </Hidden>
            )}

            <div className={classes.spacer} />
            <Hidden smDown={true} implementation="css">
              {!auth.user && (
                <>
                  <Button component={Link} to="/auth/signin" color="inherit">
                    Sign in
                  </Button>
                  <Box component="span" ml={1}>
                    <Button
                      component={Link}
                      to="/auth/signup"
                      variant="contained"
                      color="primary"
                    >
                      Sign up
                    </Button>
                  </Box>
                </>
              )}

              {auth.user && (
                <>
                  <Button
                    color="inherit"
                    aria-label="Account"
                    aria-controls="account-menu"
                    aria-haspopup="true"
                    onClick={(event) => {
                      handleOpenMenu(event, "account-menu");
                    }}
                  >
                    Account
                    <ExpandMoreIcon className={classes.buttonIcon} />
                  </Button>
                  <Menu
                    id="account-menu"
                    open={
                      menuState && menuState.id === "account-menu"
                        ? true
                        : false
                    }
                    anchorEl={menuState && menuState.anchor}
                    getContentAnchorEl={undefined}
                    onClick={handleCloseMenu}
                    onClose={handleCloseMenu}
                    keepMounted={true}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    {/* <MenuItem component={Link} to="/dashboard">
                      Dashboard
                    </MenuItem> */}
                    <MenuItem component={Link} to="/settings/general">
                      Settings
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={(event) => {
                        auth.signout();
                      }}
                    >
                      Signout
                    </MenuItem>
                  </Menu>
                </>
              )}

              {/* <IconButton
                color="inherit"
                onClick={darkMode.toggle}
                style={{ opacity: 0.6 }}
              >
                {darkMode.value && <NightsStayIcon />}

                {!darkMode.value && <WbSunnyIcon />}
              </IconButton> */}
            </Hidden>
            <Hidden mdUp={true} implementation="css">
              <IconButton
                onClick={() => {
                  setDrawerOpen(true);
                }}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <List
          className={classes.drawerList}
          onClick={() => setDrawerOpen(false)}
        >
          {auth.user && (
            <>
              <ListItem component={Link} to="/dashboard" button={true}>
                <ListItemText>Dashboard</ListItemText>
              </ListItem>
              <ListItem component={Link} to="/inventory" button={true}>
                <ListItemText>Inventory</ListItemText>
              </ListItem>
              <Divider />
            </>
          )}

          {(auth.user?.app_metadata?.userrole === "ADMIN" ||
            auth.user?.app_metadata?.userrole === "MASTER ADMIN") && (
            <>
              <ListItem component={Link} to="/users" button={true}>
                <ListItemText>Users</ListItemText>
              </ListItem>
              <ListItem component={Link} to="/catalog" button={true}>
                <ListItemText>Inventory Catalog</ListItemText>
              </ListItem>
              <ListItem component={Link} to="/locations" button={true}>
                <ListItemText>Location Management</ListItemText>
              </ListItem>
              <ListItem component={Link} to="/groups" button={true}>
                <ListItemText>Group Management</ListItemText>
              </ListItem>
              <Divider />
            </>
          )}
          <ListItem component={Link} to="/about" button={true}>
            <ListItemText>About</ListItemText>
          </ListItem>
          <ListItem component={Link} to="/faq" button={true}>
            <ListItemText>Pricing</ListItemText>
          </ListItem>
          <ListItem component={Link} to="/about" button={true}>
            <ListItemText>FAQ</ListItemText>
          </ListItem>
          <ListItem component={Link} to="/contact" button={true}>
            <ListItemText>Contact</ListItemText>
          </ListItem>
          <ListItem
            button={true}
            component="a"
            href="https://medium.com"
            target="_blank"
            rel="noreferrer"
          >
            <ListItemText>Blog</ListItemText>
          </ListItem>

          {!auth.user && (
            <>
              <ListItem component={Link} to="/auth/signin" button={true}>
                <ListItemText>Sign in</ListItemText>
              </ListItem>
              <ListItem>
                <Button
                  component={Link}
                  to="/auth/signup"
                  variant="contained"
                  color="primary"
                >
                  Sign up
                </Button>
              </ListItem>
            </>
          )}

          {auth.user && (
            <>
              <ListItem component={Link} to="/settings/general" button={true}>
                <ListItemText>Settings</ListItemText>
              </ListItem>
              <Divider />
              <ListItem
                button={true}
                onClick={(event) => {
                  auth.signout();
                }}
              >
                <ListItemText>Sign out</ListItemText>
              </ListItem>
            </>
          )}

          {/* <ListItem>
            <IconButton
              color="inherit"
              onClick={darkMode.toggle}
              style={{ opacity: 0.6 }}
            >
              {darkMode.value && <NightsStayIcon />}

              {!darkMode.value && <WbSunnyIcon />}
            </IconButton>
          </ListItem> */}
        </List>
      </Drawer>
    </Section>
  );
}

export default Navbar;
