import * as React from "react";
import { useState, useEffect } from "react";

import supabase from "../../util/supabase";
import EditUserInfo from "./EditUserInfo";
import EnhancedUsersTableHead from "./EnhancedUsersTableHead";
import EnhancedUsersTableToolbar from "./EnhancedUsersTableToolbar";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@material-ui/icons/Edit";

function createData(id, name, email, role) {
  return {
    id,
    name,
    email,
    role,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

EnhancedUsersTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

EnhancedUsersTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function UserManagement(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loading, setLoading] = React.useState(true);
  const [rows, setRows] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [editUser, setEditUser] = useState(null);

  const role = JSON.parse(localStorage.getItem("userRole"));

  // const role = tokendata.user.app_metadata.userrole;

  const getUsers = async () => {
    const { data, error } = await supabase.from("users").select("*");
    if (error) console.error("Error fetching user org id:", error);
    else return data;
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const org_users = await getUsers();
      if (org_users) {
        const newRows = org_users.map((user) =>
          createData(user.id, user.name, user.email, user.role)
        );
        setRows(newRows);
      }
      setLoading(false);
    };

    if (editUser === null) {
      fetchData();
    }
  }, [editUser]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows;
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (row) => selected.some((item) => item.name === row.name);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  function returnToInventory(event) {
    event.preventDefault();
    setRows([]);
    // setLocationInventory(null);
    props.setSelectedInventory(null);
  }

  if (editUser) {
    return <EditUserInfo userEmail={editUser} setEditUser={setEditUser} />;
  } else {
    return (
      <>
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <EnhancedUsersTableToolbar
              numSelected={selected.length}
              // selected={selected}
              // updateRows={setRows}
              // inventoryItems={rows}
              // locationId={props.selectedInventory.location_id}
              // locationGroups={props.locationGroups}
              // locations={props.locations}
            />
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedUsersTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell component="th" scope="row" colSpan={5}>
                        Loading...
                      </TableCell>
                    </TableRow>
                  ) : (
                    stableSort(rows, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onMouseEnter={() => setHoveredRow(index)} // Set hovered row index
                            onMouseLeave={() => setHoveredRow(null)} // Reset hovered row index
                            // onClick={(event) => handleClick(event, row)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                          >
                            {/* <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell> */}
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              // padding="none"
                            >
                              {row.name || "N/A"}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                maxWidth: "200px",
                                // overflow: "hidden",
                                // textOverflow: "ellipsis",
                                // whiteSpace: "nowrap",
                              }}
                            >
                              {row.email}
                            </TableCell>
                            <TableCell align="left">
                              {row.role.charAt(0).toUpperCase() +
                                row.role.slice(1).toLowerCase()}
                            </TableCell>
                            <TableCell align="left">
                              <IconButton
                                disableRipple={true}
                                disabled={role !== "MASTER ADMIN"}
                                onClick={() => setEditUser(row.email)}
                                style={{
                                  visibility:
                                    hoveredRow === index ? "visible" : "hidden",
                                  padding: 0,
                                  margin: 0,
                                  height: "100%",
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  )}

                  {loading ||
                    (emptyRows > 0 && (
                      <TableRow
                        style={{ height: (dense ? 33 : 53) * emptyRows }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
          />
        </Box>
      </>
    );
  }
}
