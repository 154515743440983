import React from "react";
import Container from "@material-ui/core/Container";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  accordion: {
    // Remove shadow
    boxShadow: "none",
    "&:before": {
      // Remove default divider
      display: "none",
    },
    // Add a custom border
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  summary: {
    minHeight: 78,
  },
  summaryContent: {
    margin: "0 !important",
  },
}));

function FaqSection(props) {
  const classes = useStyles();

  const items = [
    {
      question:
        "What type of companies are a good fit for CountableInventory.io?",
      answer: [
        "CountableInventory.io is designed to help project-based businesses track their internal inventory. Internal inventory is categorized by any ",
        "inventory with rotating stock that is not sold as an individual unit.",
        "Managed IT Services, HVAC, Plumbing, Solar, Medical Offices, Construction, Equipment Rental Companies, and more.",
      ],
    },
    {
      question:
        "Can I use QR codes to automatically adjust inventory levels when I use inventory or receive new inventory?",
      answer: "Yes",
    },
    {
      question:
        "Can I use CountableInventory.io for my retail store or for e-commerce?",
      answer:
        "There are far better solutions that integrate with P.O.S and e-commerce fulfillment systems. Ask us for a recommendation.",
    },
    {
      question: "Can CountableInventory.io support multiple locations?",
      answer:
        "Yes. See all inventory collectively or by location and easily send and receive transfers from one location to another.",
    },
    {
      question: "Can CountableInventory.io support fleet vehicles?",
      answer:
        "Fleet vehicles can be set as inventory locations and assigned to individual employees for accountability.",
    },
    {
      question:
        "Will CountableInventory.io notify me when my inventory needs to be reordered?",
      answer:
        "You will never stock out of what you need with reorder set points that notify you when it is time to place a purchase order for inventory replenishment.",
    },
    {
      question: "How many locations can I use?",
      answer:
        "Potentially an unlimited number of locations. Locations can be set up as temporary or long term. Inventory items can easily be transferred and verified with QR code scanning.",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {items.map((item, index) => (
          <Accordion
            classes={{
              root: classes.accordion,
              expanded: classes.expanded,
            }}
            key={index}
          >
            <AccordionSummary
              classes={{
                root: classes.summary,
                content: classes.summaryContent,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-panel-${index}`}
            >
              <Typography variant="h6">{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails id={`faq-panel-${index}`}>
              <Typography>
                {Array.isArray(item.answer) ? (
                  <>
                    {item.answer[0]}
                    <strong>{item.answer[1]}</strong>
                    <br />
                    <br />
                    {item.answer[2]}
                  </>
                ) : (
                  item.answer
                )}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;
