import React, { useState, useEffect } from "react";

import supabase from "../../util/supabase";
import DisplaySelectedInventory from "./DisplaySelectedInventory";

import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

function Inventory(props) {
  const [locations, setLocations] = useState(null);
  const [locationGroups, setLocationGroups] = useState(null);
  const [selectedInventory, setSelectedInventory] = useState(null);

  const getLocations = async () => {
    const { data, error } = await supabase.from("locations").select("*");
    if (error) console.error("Error fetching locations:", error);
    else return data;
  };

  const getLocationGroups = async () => {
    const { data, error } = await supabase.from("location_groups").select("*");
    if (error) console.error("Error fetching user org id:", error);
    else return data;
  };

  useEffect(() => {
    const fetchData = async () => {
      const locations = await getLocations();
      setLocations(locations);
      const location_groups = await getLocationGroups();
      setLocationGroups(location_groups);
    };
    fetchData();
  }, []);

  const HoverTypography = styled(Typography)({
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)", // Change this to your preferred color or leave it as is
    },
  });

  const handleSelect = (selectedLocation) => {
    // console.log(selectedLocation);
    setSelectedInventory(selectedLocation);
  };

  if (!selectedInventory) {
    return (
      <>
        <div>
          {locationGroups &&
            [...locationGroups]
              .sort((a, b) => a.group_name.localeCompare(b.group_name))
              .map((group, index) => {
                let relatedLocations = locations.filter(
                  (location) => location.location_group === group.group_name
                );
                // Sorting related locations in alphabetical order
                relatedLocations.sort((a, b) =>
                  a.location_name.localeCompare(b.location_name)
                );
                return (
                  <Accordion
                    key={index}
                    disabled={relatedLocations.length === 0}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index + 1}a-content`}
                      id={`panel${index + 1}a-header`}
                    >
                      <Typography>{group.group_name}</Typography>
                    </AccordionSummary>
                    <List>
                      {relatedLocations.map((location, locIndex) => (
                        <ListItem disablePadding>
                          <ListItemButton
                            key={locIndex}
                            onClick={() => handleSelect(location)}
                          >
                            <ListItemText primary={location.location_name} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Accordion>
                );
              })}
        </div>
      </>
    );
  } else {
    return (
      <>
        <DisplaySelectedInventory
          selectedInventory={selectedInventory}
          setSelectedInventory={setSelectedInventory}
          locationGroups={locationGroups}
          locations={locations}
        />
      </>
    );
  }
}

export default Inventory;
